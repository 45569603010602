import React from 'react';
import { Router } from '@reach/router';
import Navbar from '../components/Navbar';
import HomePage from './index';
import Contact from './contact';
import Farm from './the-farm';

const App = () => (
  <Router>
    <HomePage path="/" />
    <Contact path="/contact" component={Contact} />
    <Farm path="/contact" component={Farm} />
    <Navbar path="/" component={Navbar} />
  </Router>
);

export default App;
